<template>
  <el-dialog
    :title="titleDialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="600px"
    class="dialog"
    center
  >
    <div class="contents">
      <div class="tooltip">{{ tooltip }}</div>
      <el-form :model="formData" ref="refForm" :rules="formRules">
        <el-form-item label="备注：">
          <el-input
            type="textarea"
            v-model="formData.remark"
            :rows="3"
            placeholder="输入备注"
            maxlength="200"
            show-word-limit
            clearable
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import {
  postSupplierWithdrawalStatus,
  postSupplierUpdateState,
} from "@/api/general/supplier";
import { SUPPLIER_STATE_ENUM, IS_WITHDRAWAL } from "../../utils/enum/index";

export default {
  name: "SupplierEditDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      titleDialog: "",
      tooltip: "", // 顶部操作说明
      handleType: "", // 操作类型
      formData: {
        id: "", // 供应商ID
        state: "", // 禁用状态
        is_withdrawal: "", // 提现状态
        remark: "", // 备注
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      formRules: {},
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data, type) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
      data && this.setFormData(data, type);
    },
    /**
     * 回显信息
     */
    setFormData(data, type) {
      this.handleType = type;
      this.formData.id = data.id;
      if (type == "review") {
        // 审核中增加禁用
        this.formData.state = SUPPLIER_STATE_ENUM["2"].value;
        const done = "禁用";
        this.titleDialog = `${done}供应商`;
        const last = "禁用后将不可登录,是否确认？";
        this.tooltip = `您将${this.titleDialog}【${data.title}】, ${last}`;
      }
      if (type === "disabled") {
        // 审核中、禁用中，点击都展示启用
        const disabled = [
          SUPPLIER_STATE_ENUM["0"].value,
          SUPPLIER_STATE_ENUM["2"].value,
        ].includes(data.state);
        this.formData.state =
          (disabled && SUPPLIER_STATE_ENUM["1"].value) ||
          SUPPLIER_STATE_ENUM["2"].value;
        const done = (disabled && "启用") || "禁用";
        this.titleDialog = `${done}供应商`;
        const last =
          (disabled && "启用后可正常登录,是否确认？") ||
          "禁用后将不可登录,是否确认？";
        this.tooltip = `您将${this.titleDialog}【${data.title}】, ${last}`;
      }
      if (type === "withdrawal") {
        const withdrawal = data.is_withdrawal === IS_WITHDRAWAL.yes.value;
        this.formData.is_withdrawal = withdrawal
          ? IS_WITHDRAWAL.no.value
          : IS_WITHDRAWAL.yes.value;
        const done = (withdrawal && "禁止") || "允许";
        this.titleDialog = `${done}供应商提现`;
        this.tooltip = `您将${done}供应商【${data.title}】提现，是否确认？`;
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.titleDialog = "";
      this.tooltip = "";
      this.handleType = "";
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      const params = {
        ...this.formData,
      };
      if (this.handleType === "review") {
        delete params.is_withdrawal;
        this.ajaxPostSupplierUpdateState(params);
      }
      if (this.handleType === "disabled") {
        delete params.is_withdrawal;
        this.ajaxPostSupplierUpdateState(params);
      }
      if (this.handleType === "withdrawal") {
        delete params.state;
        this.ajaxPostSupplierWithdrawalStatus(params);
      }
    },
    /**
     * 禁用/启用
     */
    async ajaxPostSupplierUpdateState(params) {
      try {
        await postSupplierUpdateState(params);
        this.$message.success("操作成功！");
        this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postSupplierUpdateState err", err);
      }
    },
    /**
     * 提现状态（禁用/启用）
     */
    async ajaxPostSupplierWithdrawalStatus(params) {
      try {
        await postSupplierWithdrawalStatus(params);
        this.$message.success("操作成功！");
        this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postSupplierWithdrawalStatus err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .tooltip {
    margin-bottom: 30px;
  }
  // /deep/ .el-form {
  // }
  .el-input {
    width: 300px;
  }
}
</style>
