var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: "编辑",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              ref: "refForm",
              attrs: { model: _vm.formData, rules: _vm.formRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "供应商名称：" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "输入供应商名称",
                      maxlength: "10",
                      disabled: "",
                    },
                    model: {
                      value: _vm.supplierName,
                      callback: function ($$v) {
                        _vm.supplierName = $$v
                      },
                      expression: "supplierName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否显示店铺：", required: "" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.EditDisplaySn },
                      model: {
                        value: _vm.formData.show_shop,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "show_shop", $$v)
                        },
                        expression: "formData.show_shop",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("不显示"),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("显示")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.formData.show_shop
                ? _c(
                    "el-form-item",
                    { attrs: { label: "店铺名称：", prop: "shop_name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "输入店铺名称",
                          maxlength: "10",
                          clearable: "",
                        },
                        model: {
                          value: _vm.formData.shop_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "shop_name", $$v)
                          },
                          expression: "formData.shop_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleSubmit } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }