<template>
    <div class="modules-wrap">
      <el-dialog
        title="签约记录"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="onHandleClose"
        width="75%"
        height="85%"
        center
      >
        <div class="content" v-loading="dialogLoading">
          <el-table
            :data="tableData"
            height="100%"
            :border="true"
            :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
          >
            
            <el-table-column
              prop="create_time"
              align="center"
              label="创建时间"
              min-width="150"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.create_time || "-" }}
              </template>
            </el-table-column>
            <el-table-column
              prop="contract_status"
              align="center"
              label="签约状态"
              min-width="150"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.contract_status==1?'签约中':'已签约' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="contract"
              align="center"
              label="合同名称"
              min-width="150"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                {{ scope.row.contract.name || "-" }}
              </template>
            </el-table-column>
           
            <el-table-column label="合同详情" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                 
                  @click="onHandleEditData(scope.row)"
                  >点击查看</el-button
                >
                
              </template>
            </el-table-column>
          </el-table>
  
          <!-- 分页 -->
          <div class="pagination-wrap">
            <el-pagination
              type="primary"
              background
              @size-change="onHandleSizeChange"
              @current-change="onHandleCurrentChange"
              :current-page="formData.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="formData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <template #footer>
          <el-button type="primary" @click="onHandleClose">返回</el-button>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import cloneDeep from "lodash/cloneDeep";
  import debounce from "lodash/debounce";
  
  
  export default {
    name: "refSupplierqyDialog",
    data() {
      return {
        dialogVisible: false,
        dialogLoading: false,
      
        /** 查询参数 */
        formData: {
          supplier_id: "",
          page: 1,
          pageSize: 10,
        },
        formDataClone: {}, // 拷贝一份数据，用于初始化
        tableData: [],
        total: 0,
        tableRow: {}, // 当前行数据
      };
    },
    methods: {
      /**
       * 初始化
       */
      onInitData(data) {
        this.dialogVisible = true;
        this.formDataClone = cloneDeep(this.formData);
        this.formData.supplier_id = data.id;
        this.tableRow = data;
        this.ajaxGetDeliveryList();
      },
      /**
       * 关闭操作
       */
      onHandleClose() {
        this.dialogVisible = false;
        this.formData = {
          ...this.formDataClone,
        };
      },
      /**
       * 获取自提点列表
       */
      async ajaxGetDeliveryList() {
        this.dialogLoading = true;
        const params = {
          ...this.formData,
        };
        try {
          const { data } = await this.$api.contract.getcontractlog(params);
          this.tableData = data.data;
          this.total = data.total;
        } catch (err) {
          console.log("ajax ajaxGetDeliveryList err", err);
        } finally {
          this.dialogLoading = false;
        }
      },
           /**
       * 每页条数发生改变
       */
      onHandleSizeChange(val) {
        this.formData.pageSize = val;
        this.ajaxGetDeliveryList();
      },
      /**
       * 页码发生改变
       */
      onHandleCurrentChange(val) {
        this.formData.page = val;
        this.ajaxGetDeliveryList();
      },
      onHandleEditData(e){
       this.$router.push({
  path: '/signature',
  query:{
    id:e.qiyuesuo_contract_id
  }
  
})
      }
   
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .modules-wrap {
    .content {
      height: 500px;
      border: 1px solid #ebeef5;
    }
  }
  .search-wrap {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 10px;
    .form-item {
      display: flex;
      align-items: center;
      height: 40px;
      .value {
        width: 207px;
      }
    }
  }
  .pagination-wrap {
    box-sizing: border-box;
    padding: 10px 0;
  }
  </style>
  