<template>
  <div>
    <el-dialog
      title="进出场会员卡号"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onHandleClose"
      width="30%"
      class="dialog"
      center
    >
      <div class="contents">
        <div class="mt-10 mb-10">
          进出场会员卡号：{{ supplierInfo.member_card }}
        </div>
        <div class="d-flex a-center">
          <div>进出场会员卡图片：</div>
          <el-image
            class="imgWrap"
            :src="supplierInfo.member_card_img"
            :preview-src-list="[supplierInfo.member_card_img]"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        v-if="supplierInfo.member_card_auth == 1"
      >
        <el-button type="primary" @click="onHandleAuth(-1)">
          取消认证
        </el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button type="info" @click="onHandleClearData"> 清空数据 </el-button>
        <el-button type="primary" @click="onHandleAuth(1)">
          完成认证
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  postSupplierMember_cardAuth,
  postSupplierMember_cardDel,
} from "@/api/generalControl/index.js";
export default {
  name: "member-card-pop",
  props: {},
  data() {
    return {
      dialogVisible: false,
      supplierInfo: {}, // 选中该条供应商信息
    };
  },
  methods: {
    // 清空数据
    onHandleClearData() {
      this.$confirm(`是否清空数据？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(async () => {
        try {
          const res = await postSupplierMember_cardDel({
            supplier_id: this.supplierInfo.id,
          });
          this.$message({
            message: "清空数据成功",
            type: "success",
            showClose: true,
          });
          this.onHandleClose(); // 关闭弹窗
          this.$emit("on-refresh-list"); // 刷新列表
        } catch (error) {
          console.log(error, "postSupplierMember_cardDel");
        }
      });
    },
    // 认证取消认证
    onHandleAuth(e) {
      this.$confirm(`是否${e == -1 ? "取消认证" : "完成认证"}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(async () => {
        try {
          const res = await postSupplierMember_cardAuth({
            supplier_id: this.supplierInfo.id,
            auth: e,
          });
          this.$message({
            message: `已${e == -1 ? "取消认证" : "完成认证"}`,
            type: "success",
            showClose: true,
          });
          this.onHandleClose(); // 关闭弹窗
          this.$emit("on-refresh-list"); // 刷新列表
        } catch (error) {
          console.log(error, "postSupplierMember_cardAuth");
        }
      });
    },
    /**
     * 初始化
     */
    onInitData(data) {
      this.supplierInfo = data;
      this.dialogVisible = true;
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .imgWrap {
    width: 150px;
    height: 150px;
  }
  .contents {
    padding-left: 30px;
  }
}
</style>
