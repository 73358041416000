<template>
  <el-dialog
    title="编辑"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form :model="formData" ref="refForm" :rules="formRules">
        <el-form-item label="供应商名称：">
          <el-input
            v-model="supplierName"
            placeholder="输入供应商名称"
            maxlength="10"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item label="是否显示店铺：" required>
          <el-radio-group v-model="formData.show_shop" @change="EditDisplaySn">
            <el-radio :label="0">不显示</el-radio>
            <el-radio :label="1">显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="店铺名称："
          prop="shop_name"
          v-if="formData.show_shop"
        >
          <el-input
            v-model="formData.shop_name"
            placeholder="输入店铺名称"
            maxlength="10"
            clearable
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { postSupplierShopName } from "@/api/general/supplier";

export default {
  name: "SupplierStoreDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      supplierName: "", // 供应商名称
      formData: {
        supplier_id: "", // 供应商ID
        show_shop: 0, // 是否显示店铺
        shop_name: "", // 店铺名称
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      sn:'',
      formRules: {
        shop_name: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入店铺名称！"));
              }
              callback();
            },
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      this.$refs.refForm?.clearValidate();
      data && this.setFormData(data);
    },
    /**
     * 回显信息
     */
    setFormData(data) {
      this.supplierName = data.title;
      this.formData.supplier_id = data.id;
      this.formData.show_shop = data?.show_shop;
      this.formData.shop_name = data?.shop_name || "";
      this.sn = data?.sn || "";
      this.EditDisplaySn(data);
    },
    //编辑显示唯一码
    EditDisplaySn() {
      if (this.formData.show_shop == 1 && this.formData.shop_name == "") {
        this.formData.shop_name = this.sn;
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.supplierName = "";
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.ajaxPostSupplierShopName();
      });
    },
    /**
     * 设置店铺
     */
    async ajaxPostSupplierShopName() {
      try {
        const params = {
          ...this.formData,
        };
        const { data } = await postSupplierShopName(params);
        console.log("ajax postSupplierShopName data", data);
        this.$message.success("设置成功");
        this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postSupplierShopName err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  /deep/ .el-form {
    margin-left: 120px;
    &-item__error {
      left: 110px;
    }
  }
  .el-input {
    width: 300px;
  }
}
</style>
